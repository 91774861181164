<template>
  <div class="inner-layout">

    <div class="page-title">고객센터</div>

    <cs-center-tab/>

    <div class="layout-filter-box">
      <mini-filter :opt="opt.cateCode" :target="cateCode" :targetTitle="'cateCode'" :title="'카테고리'" :inputType="'radio'" @set="setMiniFilter"/>
    </div>

    <div class="board-container">
      <div v-for="item in list" :key="`faq-${item.idx}`" class="board-item">
        <div class="board-title-box" @click="toggle(item.idx)" :class="{ active : item.idx === selectedIdx}">
          <span class="chip chip-outline color-6" :class="{ 'w-80' : item.cateCode == 1 }">{{ item.categoryName }}</span>
          <span class="title faq-title"><span class="color-1">Q. </span>{{ item.title }}</span>
          <span class="date">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span>
        </div>
        <div class="board-content-box" v-if="item.idx === selectedIdx">
          <div class="content">
            <span class="color-1 fs-17 fw-bold">A. </span><br>
            <span v-html="String(item.content).nl2br()"></span>
          </div>
        </div>
      </div>
      <div v-if="!list || list.length === 0" class="noDataMessage2">{{ $msg('nodata2') }}</div>
    </div>

    <infinite-loading ref="infiniteLoading" spinner="spiral" :identifier="identifier" @infinite="infiniteHandler">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>
<script>
import listMixin from "@/mixins/listMixin"
export default {
  name: "faq",
  mixins: [listMixin],
  data() {
    return {
      cateCode: 5012,
      pageSize: 20,
      selectedIdx: null,
    }
  },
  created() {
    this.setOptCateCode()
  },
  methods: {
    async setOptCateCode() {
      const { result, data } = await this.$api.getCtgry({ cate: this.cateCode });
      if (result === 'success' && data.length > 0) {
        this.opt.cateCode = data.reduce((list, data) => {
          data.text = data.categoryName
          data.value = data.cateCode
          list.push(data)
          return list
        }, [])
        this.opt.cateCode.unshift({ text: '전체', value: this.cateCode },)
      }
    },
    async infiniteHandler($state) {
      try {
        const { result, list } = await this.$api.getFaqList({
          cateCode: this.cateCode === 5012 ? null : this.cateCode,
          page: this.page,
          pageSize: this.pageSize,
        })
        if (result === 'success' && list && list.length > 0) {
          this.list = this.page == 1 ? list : this.list.concat(list)
          setTimeout(() => {
            this.page ++
            $state.loaded()
          }, 1000)
        }
        else {
          $state.complete();
        }
      } catch (error) {
        console.log(error);
      }
    },
    setMiniFilter(target, value) {
      this[target] = value
    },
    toggle(idx) {
      this.selectedIdx = this.selectedIdx == idx ? null : idx
    },
  }
}
</script>